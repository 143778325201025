import React from "react";
import { Link } from "gatsby";

export default ({ path }) => {
  return (
    <div className="next-container">
      <OnDeck path={path} />
    </div>
  );
};

const OnDeck = ({ path }) => {
  switch (path) {
    case "/about":
      return (
        <Link to="/projects" className="next-link">
          Projects <span title="arrow">&#x2794;</span>
        </Link>
      );
    case "/projects":
      return (
        <Link to="/skills" className="next-link">
          Skills <span title="arrow">&#x2794;</span>
        </Link>
      );
    case "/skills":
      return (
        <Link to="/work" className="next-link">
          Work <span title="arrow">&#x2794;</span>
        </Link>
      );
    case "/work":
      return (
        <Link to="/contact" className="next-link">
          Contact <span title="arrow">&#x2794;</span>
        </Link>
      );
    case "/contact":
      return null;
    case "/":
      return (
        <Link to="/about" className="next-link">
          About <span title="arrow">&#x2794;</span>
        </Link>
      );
    default:
      return (
        <Link to="/home" className="next-link">
          Home <span title="arrow">&#x2794;</span>
        </Link>
      );
  }
};
